"use client";

import { Trans, useTranslation } from "react-i18next";
import Translations from "@/app/constants/translations";
import AffiliateInfo from "@/app/constants/affiliate";

interface AffiliatePanelDescriptionProps {
    string: string;
    values?: Record<string, string | number>;
};

function AffiliatePanelDescription(props: AffiliatePanelDescriptionProps) {
    const { t } = useTranslation(Translations.HOME);

    return (
        <span className="font-bold">
            {t(props.string, { ...props.values })}
        </span>
    );
}

export default function AffiliatePanelText() {
    const { t } = useTranslation(Translations.HOME);

    return (
        <section className="flex flex-col gap-4 lg:gap-8">
            <h2 
                className="flex flex-col gap-3 font-bold text-3xl 
                text-neutral-950 md:text-4xl lg:leading-tight 
                lg:truncate lg:text-5xl"
            >
                {t(`${Translations.HOME}:affiliate.title`)}
            </h2>
            <p 
                className="leading-relaxed text-base text-neutral-950 
                md:text-lg lg:text-balance lg:text-lg"
            >
                <Trans
                    i18nKey={`${Translations.HOME}:affiliate.description`}
                    components={{
                        invitationCommission: (
                            <AffiliatePanelDescription
                                string={
                                    `${
                                        Translations.HOME
                                    }:affiliate.commission.invitation`
                                }
                                values={{
                                    credits: AffiliateInfo.INVITATION,
                                }}
                            />
                        ),
                        purchaseCommission: (
                            <AffiliatePanelDescription
                                string={
                                    `${
                                        Translations.HOME
                                    }:affiliate.commission.purchase`
                                }
                                values={{
                                    commission: AffiliateInfo.PURCHASE,
                                }}
                            />
                        ),
                    }}
                />
            </p>
        </section>
    );
}
